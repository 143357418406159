import request from '@/utils/request'

/**
 * 客户列表接口
 * @returns {*}
 */
export function getfacilitatorApi (params) {
    return request({
        url: 'client/channels',
        method: 'get',
        params
    })
}
/**
 * 获取客户的下拉框列表数据接口
 * @returns {*}
 */
export function get_select (params) {
  return request({
    url: 'client/channel/get_select',
    method: 'get',
    params
  })
}
/**
 * 获取保险公司资源列表接口
 * @returns {*}
 */
export function parent_select (params) {
    return request({
        url: 'client/insurance_company/parent_select',
        method: 'get',
        params
    })
}
/**
 * 获取保险公司子资源列表接口
 * @returns {*}
 */
export function son_select (id, params) {
    return request({
        url: `client/insurance_company/son_select/${id}`,
        method: 'get',
        params
    })
}

/**
 * 客户 获取客户详情
 * @returns {*}
 */
export function channelinfo (id, params) {
    return request({
        url: `client/channel/info/${id}`,
        method: 'get',
        params
    })
}

/**
 * 客户状态修改
 * @returns {*}
 */
export function set_statusApi (id, params) {
    return request({
        url: `client/channel/set_status/${id}`,
        method: 'get',
        params
    })
}
/**
 * 创建客户
 * @returns {*}
 */
export function Addclient (data) {
    return request({
        url: `client/channels`,
        method: 'post',
        data
    })
}

/**
 *  编辑客户
 * @returns {*}
 */
export function Editclient (id,data) {
    return request({
        url: `client/channels/${id}`,
        method: 'put',
        data
    })
}

/**
 *  删除客户 单一或批量
 * @returns {*}
 */
export function Delclient (id) {
  return request({
    url: `client/channels/${id}`,
    method: 'delete',
  })
}


/**
 * 客户详情 通讯录列表
 * @returns {*}
 */
export function getaddress_book (id, params) {
  return request({
    url: `client/channel/address_book?channel_id=${id}`,
    method: 'get',
    params
  })
}
/**
 * 客户详情 通讯录新增
 * @returns {*}
 */
export function Addaddress_book (data) {
  return request({
    url: `client/channel/address_book`,
    method: 'post',
    data
  })
}
/**
 * 客户详情 通讯录修改
 * @returns {*}
 */
export function Editaddress_book (id,data) {
  return request({
    url: `client/channel/address_book/${id}`,
    method: 'put',
    data
  })
}

/**
 *  客户详情 通讯录删除
 * @returns {*}
 */
export function Deladdress_book (id) {
  return request({
    url: `client/channel/address_book/${id}`,
    method: 'delete',
  })
}

/**
 * 客户详情 服务配置 获取配置
 * @returns {*}
 */
export function getservice_configuration (id,params) {
  return request({
    url: `client/channel/service_configuration/${id}`,
    method: 'get',
    params
  })
}

/**
 * 客户详情 服务配置 配置修改
 * @returns {*}
 */
export function Saveservice_aging (id,data) {
  return request({
    url: `client/channel/service_aging/${id}`,
    method: 'post',
    data
  })
}

/**
 * 客户详情 服务配置 获取配置
 * @returns {*}
 */
export function getget_dispatch_config (id,params) {
    return request({
        url: `client/channel/get_dispatch_config/${id}`,
        method: 'get',
        params
    })
}
/**
 * 客户详情 服务配置 新增OR修改派单配置接口
 * @returns {*}
 */
export function Savecreate_dispatch_config (data) {
    return request({
        url: `client/channel/create_dispatch_config`,
        method: 'post',
        data
    })
}

/**
 * 客户详情 服务配置 客户好评开关配置信息接口
 * @returns {*}
 */
export function get_owners_favorable_comment_info (id,params) {
  return request({
    url: `client/channel/get_owners_favorable_comment_info/${id}`,
    method: 'get',
    params
  })
}
/**
 * 客户详情 服务配置 客户好评开关配置修改接口
 * @returns {*}
 */
export function edit_owners_favorable_comment (data) {
  return request({
    url: `client/channel/edit_owners_favorable_comment`,
    method: 'post',
    data
  })
}


/**
 * 客户详情  合同信息
 * @returns {*}
 */
export function getreconciliation (id,params) {
  return request({
    url: `client/channel/contract_details/${id}`,
    method: 'get',
    params
  })
}

/**
 * 客户详情 合同信息 开票资料
 * @returns {*}
 */
export function getcontract_details (id,params) {
  return request({
    url: `client/channel/read_invoice/${id}`,
    method: 'get',
    params
  })
}

/**
 * 客户详情 合同信息 开票资料新增
 * @returns {*}
 */
export function save_invoice (data) {
  return request({
    url: `operation/reconciliation/invoice`,
    method: 'post',
    data
  })
}
/**
 * 客户详情 合同信息 开票资料编辑
 * @returns {*}
 */
export function Editinvoice (id,data) {
  return request({
    url: `operation/reconciliation/invoice/${id}`,
    method: 'put',
    data
  })
}

/**
 *  客户详情 合同信息 开票资料删除
 * @returns {*}
 */
export function Deldel_invoice (id) {
  return request({
    url: `operation/reconciliation/invoice/${id}`,
    method: 'delete',
  })
}

/**
 *  客户详情 合同信息 更新签约
 * @returns {*}
 */
export function save_contract_award (data) {
  return request({
    url: `client/channel/save_contract_award`,
    method: 'post',
    data
  })
}


/**
 * 客户详情 话术配置
 * @returns {*}
 */
export function geteloquence_details (id, params) {
    return request({
        url: `client/channel/eloquence_details/${id}`,
        method: 'get',
        params
    })
}
/**
 * 客户详情 话术配置 新增or修改
 * @returns {*}
 */
export function save_eloquence (data) {
    return request({
        url: `client/channel/save_eloquence`,
        method: 'post',
        data
    })
}
/**
 * 客户详情 话术配置 删除
 * @returns {*}
 */
export function Deldel_eloquence (id, params) {
    return request({
        url: `client/channel/del_eloquence?id=${id}`,
        method: 'get',
        params
    })
}


/**
 * 客户 订单列表
 * @returns {*}
 */
export function getchannel (params) {
  return request({
    url: 'order/channel/list',
    method: 'get',
    params
  })
}

/**
 * 获取合作服务商订单列表接口
 * @returns {*}
 */
export function teamworklist (params) {
  return request({
    url: 'order/teamwork/list',
    method: 'get',
    params
  })
}


/**
 * 客户 普通客户列表
 * @returns {*}
 */
export function getusers (params) {
  return request({
    url: 'users/users',
    method: 'get',
    params
  })
}
/**
 * 客户 普通客户列表 状态修改
 * @returns {*}
 */
export function Saveset_status (id, params) {
  return request({
    url: `users/set_status/${id}`,
    method: 'get',
    params
  })
}

/**
 * 客户 服务商下拉数据
 * @returns {*}
 */
export function getfacilitator (params) {
  return request({
    url: `client/facilitator/select`,
    method: 'get',
    params
  })
}

/**
 * 客户 订单来源统计接口
 * @returns {*}
 */
export function order_source_statistics (params) {
    return request({
        url: 'order/order_source_statistics',
        method: 'get',
        params
    })
}

/**
 * 客户 订单来源统计接口
 * @returns {*}
 */
export function get_order_statistics (params) {
    return request({
        url: 'client/get_order_statistics',
        method: 'get',
        params
    })
}

/**
 * 客户 服务商订单汇总统计接口
 * @returns {*}
 */
export function get_order_summarizing (params) {
    return request({
        url: 'client/get_order_summarizing',
        method: 'get',
        params
    })
}

/**
 * 客户 C端用户统计接口
 * @returns {*}
 */
export function userstatistics (params) {
    return request({
        url: 'client/user/statistics',
        method: 'get',
        params
    })
}



/**
 * 客户 渠道客户列表
 * @returns {*}
 */
export function getcustomers (params) {
    return request({
        url: 'client/customers',
        method: 'get',
        params
    })
}
/**
 * 客户 创建渠道客户
 * @returns {*}
 */
export function Addcustomers (data) {
    return request({
        url: `client/customers`,
        method: 'post',
        data
    })
}
/**
 *  客户 编辑渠道客户
 * @returns {*}
 */
export function Editcustomers (id,data) {
    return request({
        url: `client/customers/${id}`,
        method: 'put',
        data
    })
}
/**
 * 客户 渠道客户 状态修改
 * @returns {*}
 */
export function customerstatus (id, params) {
    return request({
        url: `client/customer/status/${id}`,
        method: 'get',
        params
    })
}
/**
 *  客户 删除渠道客户
 * @returns {*}
 */
export function Delcustomers (id) {
    return request({
        url: `client/customers/${id}`,
        method: 'delete',
    })
}

/**
 * 客户详情 获取水印信息
 * @returns {*}
 */
export function get_watermark_info (id,params) {
    return request({
        url: `client/channel/get_watermark_info/${id}`,
        method: 'get',
        params
    })
}

/**
 * 客户详情 获取水印配置
 * @returns {*}
 */
export function get_watermark (id,params) {
    return request({
        url: `client/channel/get_watermark_config/${id}`,
        method: 'get',
        params
    })
}
/**
 * 客户详情 创建水印配置
 * @returns {*}
 */
export function Addsave_watermark (data) {
    return request({
        url: `client/channel/save_watermark`,
        method: 'post',
        data
    })
}


/**
 * 获取平台管理VIP信息接口
 * @returns {*}
 */
export function get_vip (params) {
    return request({
        url: 'platform/get_vip',
        method: 'get',
        params
    })
}


/**
 * 客户 客户详情 计价公式列表
 * @returns {*}
 */
export function pricing_formula (id, params) {
    return request({
        url: `operation/taximeter/client/${id}/list`,
        method: 'get',
        params
    })
}

/**
 * 客户 客户详情 计价公式详情
 * @returns {*}
 */
export function Detailstaximeters (id,id1, params) {
    return request({
        url: `operation/taximeter/client/${id}/info/${id1}`,
        method: 'get',
        params
    })
}

/**
 * 客户 客户详情 计价公式 获取配置
 * @returns {*}
 */
export function taximeterget_config (id,params) {
    return request({
        url: `operation/taximeter/client/${id}/get_config`,
        method: 'get',
        params
    })
}

/**
 * 客户详情 计价公式 添加计价
 * @returns {*}
 */
export function Addtaximeters (id,data) {
    return request({
        url: `operation/taximeter/client/${id}/save`,
        method: 'post',
        data
    })
}
/**
 * 客户详情 计价公式 修改计价
 * @returns {*}
 */
export function Edittaximeters (id,id1,data) {
    return request({
        url: `operation/taximeter/client/${id}/put/${id1}`,
        method: 'put',
        data
    })
}
/**
 * 客户 客户详情 详情 计价公式 删除
 * @returns {*}
 */
export function Deltaximeter (id,id1) {
    return request({
        url: `operation/taximeter/client/${id}/delete/${id1}`,
        method: 'delete',
    })
}





/**
 * 渠道客户  计价公式列表
 * @returns {*}
 */
export function qudaopricing_formula (id, params) {
  return request({
    url: `operation/taximeter/channel/${id}/list`,
    method: 'get',
    params
  })
}

/**
 * 渠道客户  计价公式详情
 * @returns {*}
 */
export function qudaoDetailstaximeters (id,id1, params) {
  return request({
    url: `operation/taximeter/channel/${id}/info/${id1}`,
    method: 'get',
    params
  })
}
/**
 * 渠道客户 渠道客户详情 计价公式 获取配置
 * @returns {*}
 */
export function channelget_config (id,params) {
    return request({
        url: `operation/taximeter/channel/${id}/get_config`,
        method: 'get',
        params
    })
}

/**
 * 渠道客户  计价公式 添加计价
 * @returns {*}
 */
export function qudaoAddtaximeters (id,data) {
  return request({
    url: `operation/taximeter/channel/${id}/save`,
    method: 'post',
    data
  })
}
/**
 * 渠道客户 计价公式 修改计价
 * @returns {*}
 */
export function qudaoEdittaximeters (id,id1,data) {
  return request({
    url: `operation/taximeter/channel/${id}/put/${id1}`,
    method: 'put',
    data
  })
}
/**
 * 渠道客户 详情 计价公式 删除
 * @returns {*}
 */
export function qudaoDeltaximeter (id,id1) {
  return request({
    url: `operation/taximeter/channel/${id}/delete/${id1}`,
    method: 'delete',
  })
}
